<template>
	<div class="modifyBj" @click="hide">
		<div class="imgBox mr10">
			<img class="img1 mb10" :src="imgurl" @click.stop="" />
		</div>
		<div class="flex-col-ali-sta" v-if="imgList.length > 1">
			<img class="img2 mb10" :class="index == i ? '':'opac4'" v-for="(item, i) in imgList" :src="item" @click.stop="showImg(i)" />
		</div>
	</div>
</template>
<script>
	export default {
		props: ['imgList'],
		data() {
			return {
				imgurl: '',
				index: 0
			}
		},
		methods: {
			showImg(i){
				this.index = i
				this.imgurl = this.imgList[i]
			},
			hide(){
				this.$emit('hideImg')
			}
		},
        mounted() {
			this.imgurl = this.imgList[this.index]
			console.log('imgList', this.imgList, this.imgurl)
        },
	}
</script>
<style lang="less" scoped>
.modifyBj{ z-index: 10000; }
.imgBox{ height: 800px; overflow: auto;}
.img1{ width: 600px; height: auto; }
.img2{ width: 50px; height: auto; }
</style>