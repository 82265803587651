<template>
	<section class="permission">
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<el-form :inline="true" class="mb10">
				<el-form-item>
					<el-date-picker v-model="time" type="daterange"	align="right" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
				</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-input v-model="store_name" placeholder="店铺名查询" class="w120"></el-input>
				</el-form-item>
				<el-form-item>
					<el-input v-model="username" placeholder="店铺号查询" class="w120"></el-input>
				</el-form-item>
					<el-form-item >
						<el-select v-model="aleady_upload" placeholder="是否上传票样" clearable clearable class="w120">
							<el-option v-for="item in is_upload_tkimg_status" :key="item.label" :label="item.value" :value="item.label"></el-option>
						</el-select>
					</el-form-item>	
				<el-form-item>

				</el-form-item>
					<el-form-item >
						<el-select v-model="check_result" placeholder="程序检查结果" clearable clearable class="w120">
							<el-option v-for="item in check_result_status" :key="item.label" :label="item.value" :value="item.label"></el-option>
						</el-select>
					</el-form-item>	
				<el-form-item>

					<el-button type="primary" @click="getList(0)" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</el-col>
		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">
			<el-table-column prop="id" label="方案号" width="100" align="center"></el-table-column>
			<el-table-column prop="store_id" label="店铺ID" width="100" align="center"></el-table-column>
			<el-table-column prop="username" label="店铺账号" width="100" align="center"></el-table-column>
			<el-table-column prop="name" label="店铺名" width="150"></el-table-column>
			<el-table-column prop="finishtime" label="结账时间" width="150"></el-table-column>
			<el-table-column prop="money" label="方案金额" width="80" align="center"></el-table-column>
			<el-table-column prop="ticket" label="方案票数" width="80" align="center"></el-table-column>
			<el-table-column prop="tk_img" label="票样图片" width="80" align="center"></el-table-column>
			<el-table-column prop="check_result" label="是否检验过" width="120" align="center"></el-table-column>
			<el-table-column prop="desci" label="订单备注" width="420" align="center"></el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button type="primary" plain size="small" @click="seeinfo(scope.row.id,scope.row.user_id,scope.row.lottery_type,scope.row.store_id)">查看详情</el-button>
					<el-button type="primary" plain size="small" @click="setChuanpiao(scope.row.store_id)">备注</el-button>
					<el-button v-if="scope.row.user_require_ticket_img > 0" type="primary" plain size="small" @click="noCheckTicket(scope.row)">不抽查</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<!--分页-->
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page" :page-sizes="pagesizes" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-col>
	</section>
</template>

<script>
	let _t;
	import showimg from '@/components/showImgList'
	import { getPlanStatisticsList, setPass } from '../../api/api';
	import common from '@/utils/common';
	export default {
		components: { showimg },
		data() {
			return {
				order_num: '',
				time:[],
				username: '',
				store_name: '',
				aleady_upload: '',
				check_result: '',
				listData: [],
				//分页
				total: 0,
				page: 1,
				pagesize: 10,//每页条数
				pagesizes: [10, 20, 50, 100],//可选条数
				listLoading: false,
				imgList: [],
				is_upload_tkimg_status:[
					{
						value:'全部',
						label: ''
					},{
						value:'已上传',
						label: '1'
					},{
						value:'未上传',
						label: '0'
					}
				],

				check_result_status:[
					{
						value:'全部',
						label: ''
					},{
						value:'检查通过',
						label: '1'
					},{
						value:'票样不合格',
						label: '0'
					}
				],

			}
		},		
		created() {
			_t = this
			this.time = [common.getDay(-3), common.dateFormater('YYYY-MM-DD')];
			//获取列表
			this.getList(1);
		},
		methods: {

			seeinfo:function(id,user_id,lottery_type,store_id){
				// this.$router.push({ name: '方案详情', query: { order_id: id,user_id: user_id,lottery_type: lottery_type,store_id: store_id }})
				const routeUrl = this.$router.resolve({
					path: "/operate/plan/planDetail",
					query: {
						order_id: id,
						user_id: user_id,
						lottery_type: lottery_type,
						store_id: store_id
					}
				});
				window.open(routeUrl.href, '_blank');
			},

			//分页
			handleSizeChange(val) {
				this.pagesize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			//获取列表
			getList(val) {
                if(val == '0') this.page = 1
				const para = {
					page: this.page,
					pagesize: this.pagesize,
					order_num: this.order_num,
					username: this.username,
					store_name: this.store_name,
					start_time: this.time[0],
					end_time: this.time[1],
					aleady_upload: this.aleady_upload,
					check_result: this.check_result
				};
				this.listLoading = true;
				getPlanStatisticsList(para).then((res) => {
					if(res.code == 1){
						this.total = res.data.count;
						this.listData = res.data.data;
						this.listLoading = false;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},

			noCheckTicket: function(obj){
				let para = {
					order_id: obj.order_id || obj.order_num || obj.id,
					lottery_type: obj.lottery_type,
					store_id: obj.store_id,
				};
				setPass(para).then((res) => {
					if(res.code == 1) this.getList();
					else this.$notify.error('错误');
				});
			},

			
		},
	}
</script>
<style lang="less" scoped>
.el-form-item{ margin-bottom: 6px; }
.img1{ width: 64px; height: 64px; }
.mlr10{ margin: 0 10px;}
.mText{ width: 500px; border: none; background-color: #f7f7f7; padding: 10px; box-sizing: border-box; border-radius: 5px; }
</style>
